(function() {
    angular.module('informaApp')
        .component('newFeaturePopupConfirmationDialog', {
            templateUrl: 'components/new-feature-popup/new-feature-popup-confirmation-dialog/template.html',
            controller: NewFeaturePopupConfirmationDialogController,
            bindings: {
                onInitialize: '<',
                onClose: '<',
                onDismiss: '<'
            }
        });

    function NewFeaturePopupConfirmationDialogController() {

    }
})();
